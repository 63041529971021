<template>
    <div class="table-page">
        <div class="">
            <div style="float: right">
                <el-button type="default" size="small" @click="handleExport">导出Excel</el-button>
            </div>
            <el-breadcrumb separator="/" style="margin-bottom: 20px">
                <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>系统日志</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            function datetimeFormat(v) {
                let date = new Date(v.CreatedAt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            }

            return {
                loading: false,
                table: {
                    columns: [
                        {title: '时间', field: 'CreatedAt', width: '200', formatter: datetimeFormat},
                        {title: '用户', field: 'username', width: '120'},
                        {title: '来源', field: 'tag', width: '120'},
                        {title: '内容', field: 'content', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                keywords: '',
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/oplog/?page=${this.table.page}&pageSize=${this.table.pageSize}&keywords=${this.keywords}`)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.data
                    this.table.total = resp.data.data.total
                }
                this.loading = false
            },
            handleSearch(e) {
                console.log(e)
                this.getData()
            },
            handleExport() {
                let url = `/oplog/export/xlsx`
                this.$http.get(url, {
                    responseType: 'arraybuffer',
                    xsrfHeaderName: 'Authorization',
                }).then(res => {
                    const filename = res.headers["content-disposition"]
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', filename.split("filename=")[1])
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
